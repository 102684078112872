<template>
  <div class="News">
    <div class="NewList banner">
      <div class="News-title">
        最新公告
        <div class="en">Announcement</div>
      </div>
      <!-- 列表 -->
      <div class="News-list">
        <div class="item" v-for="(item,index) in List" :key="index" @click="GetNewsDetails(item)">
          <div class="left">{{item.newsTitle}}</div>
          <div class="day">{{formatDate(item.createTime)}}</div>
        </div>
      </div>
      <!-- 分页 -->
      <Pagination :total="Total" :size="config.pageSize" :page="config.pageNum" @handlePage="PageChange" v-if="Total > config.pageSize" />
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
export default {
  data() {
    return {
      config: {
        newsType: 1,
        pageSize: 10,
        pageNum: 1,
      },
      List: [],
      Total: "",
    };
  },
  created() {
    this.GetNewsList();
  },
  components: {
    Pagination,
  },
  methods: {
    GetNewsList() {
      let url = "/app-api/hiring/news/page";
      let Ids = `?pageNo=${this.config.pageNum}&pageSize=${this.config.pageSize}&newsType=${this.config.newsType}`;
      this.$axios.get(url + Ids).then((res) => {
        this.List = res.data.data.list;
        this.Total = res.data.data.total;
      });
    },
    // 页面切换
    PageChange(val) {
      this.config.pageNum = val;
      this.GetNewsList();
    },
    // 点击进入公共详情
    GetNewsDetails(item) {
      this.$router.push({ path: "/News/Details", query: { id: item.id } });
    },
  },
};
</script>
<style lang="less" scoped>
.News {
  background: #f7f9fa;
  padding-top: 30px;
  padding-bottom: 30px;
  .NewList {
    background: #ffffff;
    padding: 60px 100px;
    box-sizing: border-box;
  }
  .News-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #fc3737;
    line-height: 28px;
    font-size: 24px;
    .en {
      font-weight: 400;
      color: #999999;
      line-height: 19px;
      font-size: 16px;
      margin-left: 10px;
    }
  }
  .News-list {
    margin-top: 20px;
    .item {
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px dashed #999999;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        .left {
          color: #fc3737;
        }
        // .day {
        //   color: #fc3737;
        // }
      }
      .left {
        font-weight: 400;
        color: #000000;
        line-height: 19px;
        font-size: 16px;
        cursor: pointer;
      }
      .day {
        font-weight: 400;
        color: #999999;
        line-height: 19px;
        font-size: 16px;
      }
    }
  }
}
</style>
